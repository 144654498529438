/** @jsxImportSource @emotion/react */
import Linkify from 'react-linkify'
import { Text, TextExtendedProps } from 'grommet'

import { BaseGroup } from 'db-tables'
import ExternalAnchor from 'components/ExternalAnchor'

type Props = {
  group: BaseGroup
} & TextExtendedProps
export default function GroupDescription({ group, ...rest }: Props) {
  return (
    <Text size="small" {...rest}>
      <Linkify
        componentDecorator={(href, key) => (
          <ExternalAnchor
            key={key}
            label={href}
            href={href}
            size="small"
            color="text"
            css={{
              display: 'inline-block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '18rem',
              verticalAlign: 'bottom',
            }}
          />
        )}>
        {group.description}
      </Linkify>
    </Text>
  )
}
