import { domain, flags } from 'routes'
import { ParsedUrlQuery } from 'querystring'

type GroupUrl = {
  shortCode: string
  showResults: boolean
  locale: string | undefined
  query?: ParsedUrlQuery
}
export const plotUrl = ({ shortCode, showResults, locale, query }: GroupUrl) => {
  locale = !locale || locale === 'en' ? '' : `/${locale}`
  return `${domain}${locale}/group/${shortCode}${showResults ? '/results' : ''}${flags(query)}`
}
