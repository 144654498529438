/** @jsxImportSource @emotion/react */
import { useTranslation } from 'next-i18next'
import { Box, Button, Text } from 'grommet'
import { Table } from 'grommet-icons'

import { colors } from 'theme'
import downloadGroupCsvResults from 'util/downloadGroupCsvResults'
import { verticalButtonCss } from 'components/VerticalButton'
import { trackEvent } from 'utils/useGTM'

type GroupExportParams = {
  groupId: string
  name: string
}

export default function GroupExport({ groupId, name }: GroupExportParams) {
  const { t } = useTranslation()
  return (
    <Button
      css={{
        color: colors.brand as string,
        display: 'flex',
        ...verticalButtonCss,
      }}
      plain
      label={
        <Box direction="column">
          <Box align="center">
            <Table color="brand" />
          </Box>
          <Text size="small" textAlign="center" className="title">
            {t('cta.export')}
          </Text>
        </Box>
      }
      onClick={async () => {
        await downloadGroupCsvResults(groupId, name)
        trackEvent({ event: 'ExportCsvFromGroupList' })
      }}
    />
  )
}
