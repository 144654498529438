import { Scope, ScopeKey } from 'util/scopes'
import { Group, Link, Globe, IconProps, Secure, Insecure } from 'grommet-icons'

type Props = {
  scope: Scope
} & IconProps

export default function ScopeIcon({ scope, ...rest }: Props) {
  const color = `scope-${scope}`
  if (scope === Scope.organization) {
    return <Group color={color} {...rest} />
  }
  if (scope === Scope.unlisted) {
    return <Link color={color} {...rest} />
  }
  return <Globe color={color} {...rest} />
}

export const ScopeLockIcon = ({ scope }: { scope: ScopeKey }) => {
  const color = `scope-${scope}`
  switch (scope) {
    case Scope.team:
    case Scope.organization:
    case Scope.personal:
      return <Secure color={color} />
    case Scope.unlisted:
      return <Insecure color={color} />
    case Scope.public:
      return <Insecure color={color} />
  }
}
