import { ExistingGroup } from 'db-tables'

import { CommonPageProps } from './ssp'
import { loginRedirectResponse } from './login'

import { Scope } from '.prisma/client'

export { Scope }
export type ScopeKey = keyof typeof Scope

export const scopeOptions = [
  { value: Scope.public, label: Scope.public },
  { value: Scope.unlisted, label: Scope.unlisted },
  // { value: Scope.personal, label: Scope.personal },
  // { value: Scope.team, label: Scope.team },
  { value: Scope.organization, label: Scope.organization },
]
export const publicScopes: Scope[] = [Scope.public, Scope.unlisted]

export const isPublicScope = (scope: ScopeKey) => publicScopes.includes(Scope[scope])

export const isGroupRestricted = (props: CommonPageProps, group: ExistingGroup | null) => {
  if (!group) {
    return { props }
  }
  if (!props.user) {
    if (!isPublicScope(group.scope)) {
      return { props }
    }
    if (group.allow_anon === false) {
      return loginRedirectResponse(props.path)
    }
  }
}
