import { useCallback, useEffect, useState } from 'react'

import { EventType, trackEvent } from 'utils/useGTM'

export default function useCopy(event: EventType = 'CopyGroupUrl', timeout = 1500) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false)
      }
    }, timeout)
    return () => clearTimeout(timer)
  }, [copied, timeout])

  const onCopy = useCallback(() => {
    setCopied(true)
    trackEvent({ event })
  }, [event])

  return { copied, onCopy }
}
