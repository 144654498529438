import { PropsWithoutRef } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, ButtonExtendedProps } from 'grommet'

import { BaseGroup } from 'db-tables'
import ScopeIcon from 'components/ScopeIcon'

type Props = { group: BaseGroup } & PropsWithoutRef<ButtonExtendedProps>

export default function GroupScope({ group, ...props }: Props) {
  const { t } = useTranslation()
  return (
    <Button
      icon={<ScopeIcon scope={group.scope} />}
      size="small"
      title={t(`scope.${group.scope}.label`)}
      {...props}
    />
  )
}
