/** @jsxImportSource @emotion/react */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Anchor, Box, Button, DataTable, Spinner, Text } from 'grommet'
import { Link as LinkIcon, StatusGood, UserAdd, ServicePlay, Edit } from 'grommet-icons'

import { plotUrl } from 'util/plot'
import useCopy from 'hooks/useCopy'
import { useAuth } from 'hooks/useAuth'
import { GroupTabs, routes } from 'routes'
import DisplayDate from 'components/DisplayDate'
import VerticalButton from 'components/VerticalButton'
import { Group, LatestPublicGroup, BaseGroup, TopPublicGroup } from 'db-tables'

import GroupScope from './GroupScope'
import GroupDescription from '../GroupDescription'
import GroupExport from './GroupExport'

type GroupListParams = {
  groups: Group[] | TopPublicGroup[] | LatestPublicGroup[]
  showTotal?: boolean
  hideScope?: boolean
  personalGroups?: boolean
}
const GroupRow = ({ group }: { group: BaseGroup }) => {
  const { locale } = useRouter()
  const { copied, onCopy } = useCopy()
  return (
    <Box direction="row" justify="start">
      <CopyToClipboard
        text={plotUrl({ shortCode: group.short_code, locale, showResults: true })}
        onCopy={onCopy}>
        <Button
          plain
          css={{ display: 'flex', alignSelf: 'center' }}
          icon={copied ? <StatusGood size="16px" /> : <LinkIcon size="16px" />}
        />
      </CopyToClipboard>
      <Text size="small" weight="bold" css={{ whiteSpace: 'nowrap' }} margin={{ left: 'xsmall' }}>
        {group.short_code}
      </Text>
    </Box>
  )
}

const ActionsRow = ({
  group,
  personalGroups,
  hideScope = false,
}: {
  group: BaseGroup
  hideScope?: boolean
  personalGroups: boolean
}) => {
  const { user } = useAuth()
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <Box direction="row" justify="around">
      {!hideScope && <GroupScope plain group={group} />}
      <VerticalButton
        href={routes.group.view(group.short_code, router.query)}
        icon={UserAdd}
        label={t('pages.group.results.tabs.plot')}
      />
      <VerticalButton
        href={routes.group.viewTab(group.short_code, GroupTabs.results, router.query)}
        icon={ServicePlay}
        label={t('pages.group.results.tabs.overview')}
      />
      {personalGroups && (
        <VerticalButton
          href={routes.group.edit(group.short_code)}
          icon={Edit}
          label={t('cta.edit')}
        />
      )}
      {user?.id && user?.id === group.owner_id && (
        <GroupExport groupId={group.id} name={group.name} />
      )}
    </Box>
  )
}

export default function GroupList({
  groups,
  showTotal = false,
  hideScope = false,
  personalGroups = false,
}: GroupListParams) {
  const { t } = useTranslation()
  return (
    <DataTable<BaseGroup>
      step={5}
      size="100%"
      data={groups}
      paginate={{ alignSelf: 'center' }}
      // placeholder={loading ? <LoadingPlaceholder /> : undefined}
      columns={[
        {
          size: '1/2',
          align: 'start',
          property: 'name',
          header: <Text>{t('basic.name')}</Text>,
          render: (group) => (
            <Box direction="column">
              <Link href={routes.group.view(group.short_code)} passHref>
                <Anchor size="medium" color="brand">
                  {group.name}{' '}
                  {group.archived_at && (
                    <Text color="status-critical" size="xsmall">
                      {t('basic.archived')}
                    </Text>
                  )}
                </Anchor>
              </Link>
              {group.created_at && (
                <DisplayDate
                  date={group.start_time ?? group.created_at}
                  size="small"
                  color="text-xweak"
                />
              )}
              <GroupDescription group={group} />
            </Box>
          ),
        },
        showTotal
          ? {
              primary: true,
              size: '1/4',
              align: 'center',
              property: 'mood_count',
              header: <Text>{t('basic.mood-count')}</Text>,
            }
          : {
              primary: true,
              size: '1/4',
              align: 'start',
              property: 'short_code',
              header: <Text>{t('basic.short-code')}</Text>,
              render: (group) => <GroupRow group={group} />,
            },
        {
          plain: true,
          size: '1/4',
          property: 'actions',
          render: (group) => (
            <ActionsRow group={group} hideScope={hideScope} personalGroups={personalGroups} />
          ),
        },
      ]}
    />
  )
}

export const LoadingPlaceholder = () => {
  const { t } = useTranslation()
  return (
    <Box
      fill
      align="center"
      justify="center"
      direction="row"
      pad="large"
      gap="small"
      background={{ color: 'background-front', opacity: 'strong' }}>
      <Spinner />
      <Text weight="bold">{t('basic.loading')}</Text>
    </Box>
  )
}
