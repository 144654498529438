/** @jsxImportSource @emotion/react */
import { format } from 'date-fns'
import Papa from 'papaparse'

import { supabaseClient } from 'supabaseClient'

function downloadFile({ data, fileName }: { data: string; fileName: string }) {
  const blob = new Blob([data], { type: 'text/csv' })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

async function getCsvData(groupId: string) {
  const { data } = await supabaseClient
    .from('moods')
    .select(
      'id, created_at, anon, anon_user_name, user_id, session_id, journal, emotion:emotions(name, definition)',
    )
    .eq('group_id', groupId)
    .order('created_at', { ascending: true })

  if (!data) {
    return ''
  }

  return Papa.unparse({
    data: data.map((datum) => [
      datum.created_at,
      datum.emotion.name,
      datum.emotion.definition,
      datum.anon,
      datum.anon_user_name,
      datum.user_id,
      datum.session_id,
      datum.journal,
    ]),
    fields: [
      'Created At',
      'Emotion Name',
      'Emotion Definition',
      'Anonymous',
      'Anonymous username',
      'User ID',
      'Unique Session',
      'Journal Text',
    ],
  })
}

export default async function downloadGroupCsvResults(groupId: string, name: string) {
  const data = await getCsvData(groupId)
  downloadFile({
    data,
    fileName: `${name}-report-${format(new Date(), 'yyyy-MM-dd-HH:mm')}.csv`,
  })
}
