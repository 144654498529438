import { bg, es, enGB } from 'date-fns/locale'
import { formatDistanceToNow } from 'date-fns'

const matchLocale = (lang: string): Locale => {
  switch (lang) {
    case 'bg':
      return bg
    case 'es':
      return es
  }
  return enGB
}

export const formatDate = (date: string | number | Date) =>
  new Date(date).toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
export const formatDateTime = (date: string | number | Date) =>
  new Date(date).toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
export const formatRelativeDate = (date: string | number | Date, language: string) =>
  formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: matchLocale(language),
  })
