/** @jsxImportSource @emotion/react */
import { Box, Text } from 'grommet'
import { Icon } from 'grommet-icons'
import { LinkProps } from 'next/link'

import LinkAnchor from './LinkAnchor'

export const verticalButtonCss = {
  ':hover': {
    textDecoration: 'none',
    color: '#E76F51',
    svg: {
      fill: '#E76F51',
      stroke: '#E76F51',
    },
    'span.title': {
      textDecoration: 'underline',
    },
  },
}

type Props = {
  href: LinkProps['href']
  label: string
  icon?: Icon
  children?: React.ReactNode
}
export default function VerticalButton({ href, label, children, icon: Icon }: Props) {
  return (
    <LinkAnchor
      href={href}
      weight="normal"
      css={verticalButtonCss}
      label={
        <Box direction="column">
          <Box align="center">
            {Icon && <Icon color="brand" />}
            {children}
          </Box>
          <Text size="small" textAlign="center" className="title">
            {label}
          </Text>
        </Box>
      }
    />
  )
}
